<template>
  <div>
    <div class="inner_head">
      <img class="head_img" src="../../statics/images/investor/contact_us.jpg" alt="">
    </div>
    <div class="announcement">
      <div class="home_content">
        <div class="head">
          <div class="left">
            <p class="home_title">{{ announcement.shortTitle }}</p>
            <p class="home_sub">{{ announcement.title }}</p>
          </div>
        </div>
        <ul
          v-if="anno.total"
          class="list"
        >
          <li v-for="item in announcementList" :key="item.id" @click="detailArchieve(item,136)">
            <p class="text">{{ item.title }}</p>
            <p class="date">{{ item.publishDate }}</p>
          </li>
        </ul>
        <div v-else class="no_info">暂无数据</div>
        <div v-if="anno.total" class="pagination_con">
          <el-pagination background layout="prev, pager, next" :current-page="anno.currentPage" :total="anno.total" @current-change="annoCurrentChange" />
        </div>
      </div>
    </div>
    <div class="govern">
      <div class="home_content">
        <div class="head">
          <div class="left">
            <p class="home_title">{{ govern.shortTitle }}</p>
            <p class="home_sub">{{ govern.title }}</p>
          </div>
          <ul v-if="gover.total" class="list">
            <li v-for="item in governList" :key="item.id" @click="detailArchieve(item,137)">
              <p class="text">{{ item.title }}</p>
              <p class="date">{{ item.publishDate }}</p>
            </li>
          </ul>
          <div v-else class="no_info">暂无数据</div>
          <div v-if="gover.total" class="pagination_con">
            <el-pagination background layout="prev, pager, next" :current-page="gover.currentPage" :total="gover.total" @current-change="goverCurrentChange" />
          </div>
        </div>
      </div>
    </div>
    <div class="contact">
      <div class="home_content">
        <div class="pri_head">
          <p class="title">{{ contact.shortTitle }}</p>
          <p class="sub">{{ contact.title }}</p>
          <div class="info">
            <p>{{ contact.remark }}</p>
            <p class="eg">{{ contact.tips }}</p>
          </div>
        </div>
        <div class="form">
          <div class="info">
            <span>姓名：</span>
            <input v-model="form.visitorName" type="text">
          </div>
          <div class="info">
            <span>性别</span>
            <el-radio-group v-model="form.visitorSex">
              <el-radio :label="3">男</el-radio>
              <el-radio :label="6">女</el-radio>
            </el-radio-group>
          </div>
          <div class="info">
            <span>联系电话：</span>
            <input v-model="form.telephone" class="telephone" type="number">
          </div>
          <div class="info">
            <span>EMAIL：</span>
            <input v-model="form.email" type="text">
          </div>
          <div class="info">
            <span>公司名称：</span>
            <input v-model="form.corporateName" type="text">
          </div>
          <div class="info">
            <span>来访人数：</span>
            <input v-model="form.visitorNumber" type="number">
          </div>
          <div class="info info_l">
            <span>来访目的</span>
            <textarea v-model="form.visitorObjective" cols="30" rows="10" />
          </div>
        </div>
        <div class="confirm" @click="confirm">提 交</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMainContent, getArchiveListByMenuId, addVisitorRecord } from '@/plugin/api'
import { ElMessage } from 'element-plus'

export default {
  data() {
    return {
      form: { tenantId: 'xljr' },
      announcement: {
        title: '',
        shortTitle: '',
        archiveList: []
      },
      govern: {},
      contact: {},
      anno: {
        total: 0,
        currentPage: 1
      },
      announcementList: [],
      gover: {
        total: 0,
        currentPage: 1
      },
      governList: []
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        if (newVal) this.changeTop()
      }
    }
  },
  mounted() {
    this.changeTop()
    this.getInfo()
  },
  methods: {
    goverCurrentChange(number) {
      this.gover.currentPage = number
      getArchiveListByMenuId({ tenantId: 'xljr', menuId: 137, pageNum: this.gover.currentPage, pageSize: 10 }).then(res => {
        this.governList = res.data.data.archiveList
      })
    },
    annoCurrentChange(number) {
      this.anno.currentPage = number
      getArchiveListByMenuId({ tenantId: 'xljr', menuId: 136, pageNum: this.anno.currentPage, pageSize: 10 }).then(res => {
        this.announcementList = res.data.data.archiveList
      })
    },
    confirm() {
      if (!this.form.visitorName) {
        ElMessage({
          message: '来访者姓名为必填项',
          type: 'error'
        })
        return
      }
      if (!this.form.telephone) {
        ElMessage({
          message: '手机为必填项',
          type: 'error'
        })
        return
      }
      if (!this.form.visitorObjective) {
        ElMessage({
          message: '来访目的为必填项',
          type: 'error'
        })
        return
      }
      const telReg = /^[1][3,4,5,7,8][0-9]{9}$/
      if (!telReg.test(this.form.telephone)) {
        ElMessage({
          message: '手机号有误',
          type: 'error'
        })
        return
      }
      addVisitorRecord(this.form).then(res => {
        if (res.data.code === 200) {
          ElMessage({
            message: '提交成功',
            type: 'success'
          })
          this.form = { tenantId: 'xljr' }
          window.scrollTo({
            top: 0
          })
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    detailArchieve(item, num) {
      const routeData = this.$router.resolve({
        path: '/archieveDetail', query: { menuId: num, id: item.id }
      })
      window.open(routeData.href, '_blank')
      // this.$router.push({ path: '/archieveDetail', query: { menuId: num, id: item.id }})
    },
    changeTop() {
      const url = this.$route.query.url
      let sTop = 0
      switch (url) {
        case '87':
          sTop = 0
          break
        case '141':
          sTop = document.getElementsByClassName('announcement')[0].offsetTop
          break
        case '142':
          sTop = document.getElementsByClassName('govern')[0].offsetTop
          break
        case '143':
          sTop = document.getElementsByClassName('contact')[0].offsetTop
          break
      }
      window.scrollTo({
        top: sTop - 60
      })
    },
    getInfo() {
      const { query } = this.$route
      const urlType = query.fUrlType ? query.fUrlType : query.urlType
      const url = query.fUrl ? query.fUrl : query.url
      getArchiveListByMenuId({ tenantId: 'xljr', menuId: 136, pageNum: this.anno.currentPage, pageSize: 10 }).then(res => {
        this.announcementList = res.data.data.archiveList
        this.anno.total = res.data.data.total
      })
      getArchiveListByMenuId({ tenantId: 'xljr', menuId: 137, pageNum: this.gover.currentPage, pageSize: 10 }).then(res => {
        this.governList = res.data.data.archiveList
        this.gover.total = res.data.data.total
      })
      getMainContent({ tenantId: 'xljr', url, urlType }).then(res => {
        const arr = res.data.data.contentMainList[0].contentItemTreeList
        this.announcement = arr.find(item => {
          return item.id === 782
        })
        this.govern = arr.find(item => {
          return item.id === 792
        })
        this.contact = arr.find(item => {
          return item.id === 793
        })
        this.contact.tips = this.contact.children[0].remark
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.telephone::-webkit-inner-spin-button {
  -webkit-appearance: none !important;

}

.telephone::-webkit-outer-spin-button{
  -webkit-appearance: none !important;

}

.telephone[type="number"]{
  -moz-appearance: textfield;
}
.pagination_con{
  display: flex;
  margin: 20px 0;
  .el-pagination{
    margin: 0 auto;
  }
}
.announcement,.govern{
  padding: 50px 0;
  .home_content{
    .list{
      margin-top: 80px;
      li{
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        color: #666666;
        margin-bottom: 20px;
        font-size: 14px;
        &:hover{
          color: #155FAE;
        }
      }
    }
  }
}
.contact{
  background-image: url(../assets/images/investor/investor_bg.jpg);
  padding: 40px 0;
  .pri_head{
    text-align: center;
    color: #333;
    font-size: 32px;
    border-bottom: 1px solid #BBBBBB;
    padding-bottom: 30px;
    .info{
      margin-top: 30px;
      text-align: left;
      color: #101010;
      font-size: 14px;
      line-height: 30px;
      .mail{
        text-indent: 2em;
      }
      .eg{
        color: #1E50AE;
      }
    }
  }
  .form{
    display: flex;
    flex-wrap: wrap;
    padding: 50px 0 20px 0;
    .info{
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      span{
        width: 75px;
        color: #666666;
        font-size: 14px;
      }
      &:nth-child(2n){
        input{
          margin-right: 0;
        }
      }
      input{
        border: 1px solid #BBBBBB;
        height: 40px;
        margin-right: 28px;
        flex-grow: 1;
      }
      textarea{
        border: 1px solid #BBBBBB;
        flex-grow: 1;
      }
    }
    .info_l{
      width: 100%;
    }
  }
  .confirm{
    width: 175px;
    color: #fff;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    background-color: #155FAE;
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
    border-radius: 3px;
  }
}
</style>
